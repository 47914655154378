/* eslint-disable indent */
import React from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import { GatsbyImage } from "gatsby-plugin-image"


import { Link, graphql } from "gatsby"

import "lazysizes"

import makeStyles from "@material-ui/core/styles/makeStyles"

import List from "@material-ui/core/List"
import Grid from "@material-ui/core/Grid"
import Avatar from "@material-ui/core/Avatar"
import Container from "@material-ui/core/Container"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"

import BannerSlider from "../components/BannerSlider"
import DynamicBanner from "../components/banners/DynamicBanner"
import SEO from "../components/SEO"

import Breadcrumbs from "../components/Breadcrumbs"
import Navigation from "../components/Navigation"

const useStyles = makeStyles(theme => ({
  image: {
    // float: 'left',
    display: "block",
    padding: theme.spacing(2),
    maxWidth: "100%",
  },
  groupName: {
    fontSize: "1rem",
    fontWeight: 400,
  },
  groupList: {
    listStylePosition: "outside",
    padding: theme.spacing(0, 2),
    color: theme.palette.aresBlue.main,
    listStyleType: "circle",
  },

  groupItem: {
    color: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.aresBlue.main}`,

    "&:hover": {
      color: theme.palette.aresBlue.main,
    },
  },
  wideContact: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",
    },
  },
}))

const getSchema = infoItem => {
  let schema = infoItem.data.schema_json.text || ""

  return schema
}

const InfoItemPage = props => {
  const {
    data: { infoItem },
    pageContext: { locale, pageSlug, breadcrumbs },
    location,
  } = props

  const classes = useStyles()

  return (
    <>
      <SEO
        schema={getSchema(infoItem)}
        ogp={infoItem.data.ogp_tags || ""}
        meta={{
          title: infoItem.data.meta_title,
          description: infoItem.data.meta_description,
          keywords: infoItem.data.meta_keywords,
          author: "Medicina Interventionala",
        }}
        locale={locale}
        breadcrumbs={breadcrumbs}
        noindex={infoItem.data.noindex}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography
                    component="h1"
                    variant="h1"
                    className={classes.date}
                  >
                    {infoItem.data.title.text}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  {infoItem.data.image &&
                    infoItem.data.image.gatsbyImageData && (
                      <GatsbyImage
                        className={classes.image}
                        image={infoItem.data.image.gatsbyImageData}
                        alt={infoItem.data.title.text}
                      />
                    )}
                </Grid>
              </Grid>

              <Grid item>
                {infoItem.data.body1 && (
                  <Navigation content={infoItem.data.body1} />
                )}

                {infoItem.data.body1 &&
                  infoItem.data.body1.map(slice => (
                    <div key={shortid.generate()}>
                      {slice.slice_type === "repeatable_image_type" && (
                        <div className="sliceRepeatableImageType">
                          <Typography
                            component="div"
                            dangerouslySetInnerHTML={{
                              __html: slice.primary.content.html,
                            }}
                          />
                        </div>
                      )}

                      {slice.slice_type === "list" && (
                        <Grid
                          container
                          className={classes.sliceListType}
                          direction="column"
                        >
                          <Grid item>
                            <Typography
                              component="div"
                              dangerouslySetInnerHTML={{
                                __html: slice.primary.content.html,
                              }}
                            />
                          </Grid>
                          <Grid item sm={10}>
                            <List>
                              {slice.items.map(item => (
                                <ListItem
                                  alignItems="flex-start"
                                  key={shortid.generate()}
                                  className={classes.listItem}
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      alt="Item Description"
                                      src={item.list_item_image.fixed.src}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={item.list_item_content}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </Grid>
                        </Grid>
                      )}

                      {slice.slice_type === "simple" && (
                        <Typography
                          component="div"
                          dangerouslySetInnerHTML={{
                            __html: slice.primary.content.html,
                          }}
                        />
                      )}

                      {slice.slice_type === "dynamic_banner" && (
                        <div className={classes.dynamicBanner}>
                          <BannerSlider
                            autoplay={
                              (slice.primary && slice.primary.display_duration
                                ? slice.primary.display_duration
                                : 5) * 1000
                            }
                          >
                            {slice.items
                              .filter(
                                item =>
                                  item.banner_type === null ||
                                  item.banner_type === "contact" ||
                                  item.banner_type === "promotion" ||
                                  item.banner_type === "consultant"
                              )
                              .map(item => (
                                <div key={shortid.generate()}>
                                  <DynamicBanner
                                    data={item}
                                    location={location}
                                    locale={locale}
                                  />
                                </div>
                              ))}
                          </BannerSlider>
                        </div>
                      )}
                    </div>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  )
}

export default InfoItemPage

export const query = graphql`
  query PrismicInformationItemBySlug($slug: String!, $locale: String!) {
    infoItem: prismicInformationItem(uid: { eq: $slug }, lang: { eq: $locale }) {
      lang
      id
      data {
        title {
          text
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }        
        body1 {
          ... on PrismicInformationItemDataBody1Simple {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
            }
          }
          ... on PrismicInformationItemDataBody1DynamicBanner {
            id
            slice_type

            items {
              banner_type
              banner_text {
                html
                text
              }
              banner_text_2 {
                html
              }
              phone
              display_phone
              consultant_image {
                gatsbyImageData(layout: FIXED, width: 150)
              }
            }
          }
        }
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
        noindex
      }
      uid
    }

    

    
  }
`

InfoItemPage.propTypes = {
  data: PropTypes.shape({
    infoItem: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    pageSlug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
